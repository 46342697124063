import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "input", "button" ]

  connect() {
    console.log("Connected Image Controller")
  }

  toggle(e) {
    e.preventDefault()

    const id = this.data.get("id")

    this.buttonTarget.classList.remove("border-red")
    this.buttonTarget.classList.remove("border-info")

    if (this.inputTarget.value === id) {
      this.inputTarget.value = null
      this.buttonTarget.classList.add("border-info")
    } else {
      this.inputTarget.value = id
      this.buttonTarget.classList.add("border-red")
    }
  }
}
