import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "hours", "days" ]

  // Setup initial state
  connect () {
    this.showHours((_el, i) => {
      return i === 0;
    })

    this.addBorderToDay(this.daysTarget.children[0])
  }

  // Handle click events on days
  selectDate (e) {
    e.preventDefault()
    this.selected = e.currentTarget.getAttribute('data-day')

    this.addBorderToDay(e.currentTarget)

    this.showHours((el, _i) => {
      let id = el.getAttribute("id")
      return id === this.selected
    })
  }

  // Add selected border to selected day
  addBorderToDay(el) {
    el.classList.add('border-primary')
    this.siblings(el).forEach((el, i) => {
      el.classList.remove('border-primary')
    })
  }

  // Show selected set of available hours
  showHours(showFn) {
    this.hoursTarget.children.forEach((el, i) => {
      if (showFn(el, i)) {
        el.classList.remove("d-none")
        el.classList.add("d-flex")
      } else {
        el.classList.remove("d-flex")
        el.classList.add("d-none")
      }
    })
  }

  // Get the siblings for an element
  siblings (el) {
    return Array.prototype.filter.call(el.parentNode.children, function(child){
      return child !== el;
    });
  }
}
